<template>
  <div class="_bg-default pa-3">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="orange"
    ></v-progress-linear>
    <section class="white--text d-flex indigo rounded pa-3">
      <img
        src="../../../assets/img/trophy.svg"
        height="100px"
        class="mr-3"
        alt=""
      />
      <h1>Leaderboard</h1>
    </section>
    <v-row>
      <v-col cols="12" md="2">Pilih Pelatihan</v-col>
      <v-col cols="12" md="10">
        <v-select
          :options="allPelatihan.data"
          :reduce="nama => nama.id"
          v-model="selectedEvent"
          @search="query => (find = query)"
          label="nama"
          class="white"
          @input="getRank(selectedEvent)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedEvent">
      <v-col cols="12" md="6">
        <LeaderboardUser></LeaderboardUser>
      </v-col>
      <v-col cols="12" md="6">
        <LeaderboardTeam></LeaderboardTeam>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { GET_EVENT } from "../../../graphql/Event";
import LeaderboardUser from "./LeaderboardUser";
import LeaderboardTeam from "./LeaderboardTeam";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  apollo: {
    allPelatihan: {
      query: GET_EVENT,
      variables() {
        return { page: 1, first: 10, search: this.find };
      },
      error(err) {
        console.log(err);
      }
    }
  },
  data() {
    return {
      allPelatihan: null,
      find: "",
      selectedEvent: null
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.allPelatihan.loading;
    }
  },
  components: {
    vSelect,
    LeaderboardUser,
    LeaderboardTeam
  },
  methods: {
    getRank(id) {
      this.$store.dispatch("leaderboard/leadY", {
        page: 1,
        per_page: 5,
        id: parseInt(id)
      }); //get leaderboard individu
      this.$store.dispatch("leaderboard/leadX", {
        page: 1,
        per_page: 5,
        id: parseInt(id)
      }); //get leaderboard kelompok
    }
  }
};
</script>

<style scoped>
._bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>
